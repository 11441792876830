import React, { useState, useEffect } from "react";
import { Spin, Modal, Button } from "antd";

import Eye_icon from "../../../assets/images/icons/eye_icon.svg";
import CompareImg from "../../../assets/images/compare-diamond.png";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import moment from "moment";
import { handleViewDetailsPage } from "../../../actions/selectYourDiamond/selectYourDiamond";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Compare = ({ props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CompareData = useSelector(
    (state) => state.selectYourDiamondReducer.compareKey
  );
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const ParamCompareIds = new URLSearchParams(search).get("CompareIds");
  // const PageName = new URLSearchParams(search).get("PageName");

  const [filterVals, setFilterVals] = useState({
    Shape: [],
  });

  const [keyValue, setKeyValue] = useState([
    "bigImageFileName",
    "txtShape",
    "intDiamondId",
    "fltCarat",
    "txtColor",
    "fltTable",
    "modifiedDate",
    "txtPolish",
    "txtSymmetry",
    "txtClarity",
    "txtFluorescence",
    "fltDepth",
    "txtMeasurements",
    "txtCertificate",
    "txtCutGrade",
    "fltPrice",
    "action",
  ]);
  const [loading, setLoading] = useState(false);
  const [compareList, setCompareList] = useState([]);
  const [viewCertModal, setviewCertModal] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [certRow, setCertRow] = useState({});

  useEffect(() => {
    if (paramdealerId) {
      if (CompareData == "Mined") {
        if (ParamCompareIds) {
          handleLoadCompareMinedDiamondsList(paramdealerId, ParamCompareIds);
        }
      } else {
        handleLoadCompareLabDiamondsList(paramdealerId, ParamCompareIds);
      }
    }
  }, []);

  const handleLoadCompareMinedDiamondsList = (id, CompareData) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        diamondsList: CompareData.toString(),
        cType: "D",
      };

      setLoading(true);
      SelectYourDiamondService.LoadCompareDiamondsList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.Table1;

          if (message == "Success") {
            if (responseData) {
              setCompareList(responseData);
              setLoading(false);
            } else {
              setCompareList([]);
              setLoading(false);
            }
          } else {
            setCompareList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleLoadCompareLabDiamondsList = (id, CompareData) => {
    try {
      let inputData = {
        dealerID: id.toString(),
        diamondId: CompareData.toString(),
        flag: "",
        orderBy: "",
        orderType: "",
        pendantLab: "Lab",
      };

      setLoading(true);
      SelectYourDiamondService.GetRingBuilderLabCompareData(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message == "Success") {
            if (responseData) {
              setCompareList(responseData);
              setLoading(false);
            } else {
              setCompareList([]);
              setLoading(false);
            }
          } else {
            setCompareList([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleViewDetails = (details) => {
    let obj = details;
    obj["shapeFilters"] = filterVals.Shape;
    dispatch(handleViewDetailsPage(obj));
    navigate(
      "/viewDiamondDetails" +
        `?isSocialPage=True&isselectyourdiamond=false&DealerID=${paramdealerId}&diamondId=${obj.intDiamondId}&pageNo=1&isAllowed=True&pageName=${CompareData}&shapeFilters=${filterVals.Shape}&txtCutlet=${obj.txtCulet}&txtClarity=${obj.txtClarity}&intColorPriority=${obj.intColorPriority}`
    );
    window.parent.postMessage("submitted form", "*");
  };

  const handleDeleteCol = (id) => {
    let filteredArr = compareList.filter((item) => item.intDiamondId !== id);
    setCompareList(filteredArr);
  };

  const handleDelRow = (val) => {
    let filteredArr = keyValue.filter((item) => item !== val);
    setKeyValue(filteredArr);
  };

  const handleCertModalOpen = (row) => {
    setCertRow(row);
    setviewCertModal(true);
  };
  const closeCertModal = () => {
    setCertRow({});
    setviewCertModal(false);
  };

  const hideSpinner = () => {
    setIframeLoading(false);
  };

  return (
    <React.Fragment>
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="tabs__content border__block">
          <div className="diamond__details filter_section mt-3">
            <Spin spinning={loading}>
              <div className="row">
                <div className="col-md-12">
                  <div className="compare__table custom__table">
                    <table class="table  table-striped">
                      <tbody>
                        {keyValue.map((val) => (
                          <tr>
                            <th>
                              <div className="headingtable">
                                {val == "bigImageFileName" ? (
                                  ""
                                ) : val == "action" ? (
                                  ""
                                ) : (
                                  <React.Fragment>
                                    <i
                                      class="fa fa-times-circle-o"
                                      aria-hidden="true"
                                      onClick={() => handleDelRow(val)}
                                    ></i>
                                    <span>
                                      {val == "txtShape"
                                        ? t("Shape")
                                        : val == "intDiamondId"
                                        ? t("SKU")
                                        : val == "fltCarat"
                                        ? t("Carat")
                                        : val == "txtColor"
                                        ? t("Color")
                                        : val == "fltTable"
                                        ? t("Table")
                                        : val == "modifiedDate"
                                        ? t("Date Added")
                                        : val == "fltDepth"
                                        ? t("Depth")
                                        : val == "txtPolish"
                                        ? t("Polish")
                                        : val == "txtSymmetry"
                                        ? t("Symmetry")
                                        : val == "txtClarity"
                                        ? t("Clarity")
                                        : val == "txtFluorescence"
                                        ? t("Fluorescence")
                                        : val == "txtFluorescence"
                                        ? t("Fluorescence")
                                        : val == "txtMeasurements"
                                        ? t("Measurements")
                                        : val == "txtCertificate"
                                        ?t("Certificate")
                                        : val == "txtCutGrade"
                                        ? t("Cut Grade")
                                        : val == "fltPrice"
                                        ? t("Real Price")
                                        : val}
                                    </span>
                                  </React.Fragment>
                                )}
                              </div>
                            </th>
                            {compareList.map((item) => {
                              return val == "bigImageFileName" ? (
                                <React.Fragment>
                                  <td>
                                    <img
                                      style={{ width: "120px" }}
                                      src={item[val] ? item[val] : item.image}
                                    />
                                  </td>
                                </React.Fragment>
                              ) : val == "intDiamondId" ? (
                                <td>{item.txtStockNo}</td>
                              ) : val == "modifiedDate" ? (
                                <React.Fragment>
                                  <td>
                                    {moment(item[val]).format("MM/DD/YYYY")}
                                  </td>
                                </React.Fragment>
                              ) : val == "fltPrice" ? (
                                <React.Fragment>
                                  <td>
                                    {item.CurrencySign} {"  "}
                                    {parseInt(item.fltPrice)}
                                  </td>
                                </React.Fragment>
                              ) : val == "action" ? (
                                <React.Fragment>
                                  <td>
                                    <img
                                      src={Eye_icon}
                                      onClick={() => handleViewDetails(item)}
                                      className="mr-2 icon__width Hand__pointer linktextmouse"
                                    />
                                    <img
                                      src={Delete_iconbl}
                                      onClick={() =>
                                        handleDeleteCol(item.intDiamondId)
                                      }
                                      className="icon__width Hand__pointer linktextmouse"
                                    />
                                  </td>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <td>
                                    {val == "txtCertificate" ? (
                                      <p
                                        onClick={() =>
                                          handleCertModalOpen(item)
                                        }
                                        className="link-Text"
                                      >
                                        {item[val]}
                                      </p>
                                    ) : (
                                      item[val]
                                    )}
                                  </td>
                                </React.Fragment>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Spin>
            <div className="row">
              <div className="col-md-8">
                <div className="text__block">
                  <p>
                    {t("Use your diamond comparison to save all the details of the diamonds you are considering and view them all together.")}
                  </p>
                  <p>{t("This makes it easy to choose the right diamond.")}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="image__block">
                  <img src={CompareImg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={t("Certificate")}
        visible={viewCertModal}
        style={{ top: 20 }}
        className="cert_modal"
        onCancel={closeCertModal}
        footer={[
          <Button key="back" onClick={closeCertModal}>
            {t("Cancel")}
          </Button>,
        ]}
      >
        <div className="row">
          <div>
            <Spin spinning={iframeLoading}>
              <iframe
                width="100%"
                height="450"
                onLoad={hideSpinner}
                src={certRow.certificateLink}
              />
            </Spin>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Compare;
