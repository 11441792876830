import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import ChooseYourSetting from "./component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "./component/ApplicationPreview/SelectYourDiamond";
import CompleteYourRing from "./component/ApplicationPreview/CompleteYourRing";
import Layout from "./component/Layout";
// import ChooseSettingItemDetails from "./component/ApplicationPreview/ChooseSettingItemDetails";
import Compare from "./component/common/SelectYourDiamond/Compare";
import Request from "./component/common/SelectYourDiamond/Request";
import ColorPanel from "./component/ColorPanel";
import MinedDiamond from "./component/common/SelectYourDiamond/MinedDiamond";
import LabGrown from "./component/common/SelectYourDiamond/LabGrown";
import ViewDiamondDetails from "./component/common/SelectYourDiamond/ViewDiamondDetails";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import i18n from "./i18n";

const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  const fontStyle = useSelector(
    (state) => state.previewColorReducer.fontFamily
  );
  const [cookies] = useCookies(["lngCookie"]);

  const language = cookies.lngCookie;
  useEffect(() => {
    if (language) {
      /// added language check conditions
      let tempLanguage =
        language === "English" || language === "en"
          ? "en"
          : language === "Spanish" || language === "es"
          ? "es"
          : language === "Arabic" || language === "ar"
          ? "ar"
          : "en";
      i18n.changeLanguage(tempLanguage);
    }
  }, [language, i18n]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty(
      "--dynamic-font-family",
      fontStyle || "LexendDeca-Regular"
    );
  }, [fontStyle]);

  return (
    <div style={{ fontFamily: fontStyle ? fontStyle : "LexendDeca-Regular" }}>
      <React.Fragment>
        <BrowserRouter>
          <Routes>
            <Route path="/selectYourDiamond" element={<SelectYourDiamond />} />
            <Route exact path="/" element={<PrivateRoute />}>
              <Route path="/Mined" element={<MinedDiamond />} />
              <Route path="/Lab" element={<LabGrown />} />
              {/* <Route path="/Fancy" element={<FancyTab />} /> */}

              <Route path="/Request" element={<Request />} />
              <Route path="/Compare" element={<Compare />} />
              <Route path="/completeyourring" element={<CompleteYourRing />} />
              <Route
                path="/viewDiamondDetails"
                element={<ViewDiamondDetails />}
              />
              <Route
                path="/chooseYourSetting"
                element={<ChooseYourSetting />}
              />
            </Route>
          </Routes>
          <ColorPanel></ColorPanel>
        </BrowserRouter>
      </React.Fragment>
    </div>
  );
};

export default App;
